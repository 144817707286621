import { createApp } from 'vue'
import App from './App.vue'
//import * as Vue from 'vue' // in Vue 3
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import Vue3Autocounter from 'vue3-autocounter';
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons'


library.add(faTwitter)
library.add(faDiscord)
library.add(faYoutube)
library.add(faStar)


/**
 * Import Styles
 */
import '@/css/style.css'
import 'vue3-carousel/dist/carousel.css';
import '@/css/custom.css'
import '@/css/mobile.css'

const API_URL = {
    development: "http://localhost:8081/",
    production: "https://dev.blog-pir.at/",
};
 const baseURL = API_URL[process.env.NODE_ENV];

// 1. Assign app to a variable
let app = createApp(App)
app.use(VueAxios, axios)
// 2. Assign the global variable before mounting
console.log(baseURL);
app.config.globalProperties.$baseUrl = baseURL;
// 3. Use router and mount app
app.use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('vue3-autocounter', Vue3Autocounter)
    .mount('#app')



