<template>
  <div class="jumbotron jumbotron-fluid position-relative overlay-bottom" style="margin-bottom: 90px;">
    <div class="container text-center my-5 py-5">
      <h1 class="text-white mt-4 mb-4">Find your PumpkinCatz&nbsp;</h1>
      <h1 class="text-white display-1 mb-5">Cat</h1>
      <form @submit.stop.prevent="checkSearch">
        <div class="mx-auto mb-5 search-input" style="width: 100%; max-width: 600px;">
          <div v-if="searcherrors.length > 0" class="alert-warning mb-1">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="(error, i) in searcherrors" :key="i">{{ error }}</li>
            </ul>
          </div>
          <div class="input-group">
            <input v-model="value" type="text" class="form-control border-light" style="padding: 30px 25px;" placeholder="#">
            <div class="input-group-append">
              <button type="submit" class="btn btn-secondary px-4 px-lg-5">Search</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchHeader",
  data(){
    return {
      value : null,
      searcherrors : [],
      showPopper: true,
    }
  },
  methods:{
    submitClick(){
      if(!this.value) {
        this.$router.push({path: '/search', name: 'search', params: {key: this.value}, replace: true})
      }
    },
    checkSearch(){
      this.searcherrors = [];

      if(!this.value){
        this.searcherrors.push('Please Enter a Number in the Searchfield')
      }
      if(isNaN(this.value) && this.value != null){
        this.searcherrors.push('Must be a Number')
      }

      if(this.searcherrors.length === 0){
        this.$router.push({path: '/search', name: 'search', params: {key: this.value}})
      }
    }
  },
  mounted(){
    if (this.$route.params.key > 0){
      this.value = this.$route.params.key;
    }
  }
}
</script>

<style>
</style>
