<template>
 <search-header/>
  <!-- About Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 420px;">
          <div class="position-relative h-100">
            <img class="position-absolute w-100 h-100" src="@/img/about.jpg" style="object-fit: cover;">
          </div>
        </div>
        <div class="col-lg-7">
          <div class="section-title position-relative mb-4">
            <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">About Us</h6>
            <h1 class="display-4">PumpkinCatz</h1>
          </div>
          <p>PumpkinCatz is a NFT platform on Cardano. 650 PumpkinCatz are in existence as of now and each cat is unique and can only be owned once. The project is dedicated to our beloved cat and best friend – Pumpkin.
            Our ambition is to bring attention to the Cardano Ecosystem. But also to do something good – 10 % of the revenue collected will be donated to help shelterless cats.
             </p>
          <p>Because PumpkinCatz are unique and especially cute cat portraits inspired by our beloved and unfortunately already deceased cat "Pumpkin". Hence the name "PumpkinCatz". We are very committed to animal welfare and cat help, so we donate 10% of our profits to animal welfare organizations. More about this on "<router-link to="/donations">Donations</router-link>" </p>
          <div class="row pt-3 mx-0">
            <div class="col-sm-6 col-md-3 px-0">
              <div class="bg-success text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"> <vue3-autocounter ref='counter' :startAmount='0' :endAmount='500' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true' /></h1>
                <h6 class="text-uppercase text-white">First<span class="d-block">Drop</span></h6>
              </div>
            </div>
            <div class="col-md-3 px-0">
              <div class="bg-primary text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"> <vue3-autocounter ref='counter' :startAmount='0' :endAmount='111' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true'/></h1>
                <h6 class="text-uppercase text-white">Halloween<span class="d-block">Special</span></h6>
              </div>
            </div>
            <div class="col-md-3 px-0">
              <div class="bg-secondary text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"> <vue3-autocounter ref='counter' :startAmount='0' :endAmount='1000' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true' /></h1>
                <h6 class="text-uppercase text-white">Ada<span class="d-block">Donations</span></h6>
              </div>
            </div>
            <div class="col-md-3 px-0">
              <div class="bg-warning text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"> <vue3-autocounter ref='counter' :startAmount='0' :endAmount='333' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true'/></h1>
                <h6 class="text-uppercase text-white">Ada<span class="d-block">donated</span></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->
  <!-- Feature Start -->
  <div class="container-fluid bg-image" style="margin: 90px 0;">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 my-5 pt-5 pb-lg-5">
          <div class="section-title position-relative mb-4">
            <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">Charity</h6>
            <h1 class="display-4">Donations</h1>
          </div>
          <p class="mb-4 pb-2">
            From the very beginning when we got the idea of doing a NFT project in honor of our beloved cat Pumpkin we were convinced that we have to use some of the raised funds to donate to charities which care about shelterless cats (and pets).
            A minimum of 10 % of the revenue will always be allocated to a donation wallet – we set aside 1000 ADA from the initial drop and 200 ADA from the Halloween Special.
            The ₳DA that have not yet been donated are staked at <a href="https://www.hazelpool.com" target="_blank">HAZEL Stakepool</a> until the donations are handed over.
          </p>        </div>
        <div class="col-lg-5" style="min-height: 500px;">
          <div class="position-relative h-100">
            <img class="position-absolute w-100 h-100" src="@/img/feature.jpg" style="object-fit: cover;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import searchHeader from "@/components/searchHeader";

export default {
  name: 'Home',
  components: {
    searchHeader
  },
}
</script>
